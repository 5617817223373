import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import { SharedModule } from '../shared/shared.module';

import { PageBaseComponent } from './page-base/page-base.component';
import {PageHabilitationListComponent} from './page-habilitation-list/page-habilitation-list.component';
import {PageLoginComponent} from './page-login/page-login.component';
import {PageActivateAccountComponent} from './page-activate-account/page-activate-account.component';
import {PageResetPasswordComponent} from './page-reset-password/page-reset-password.component';
import {PageChangePasswordComponent} from './page-change-password/page-change-password.component';

import {UiModule} from '../ui/ui.module';



@NgModule({
  declarations: [
      PageBaseComponent,
      PageHabilitationListComponent,
      PageLoginComponent, PageActivateAccountComponent, PageResetPasswordComponent, PageChangePasswordComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        UiModule,
        ReactiveFormsModule,
        RouterModule,
        BrowserModule,
        SharedModule,
        FormsModule
    ],
  exports: [
    PageHabilitationListComponent,
    PageLoginComponent, PageActivateAccountComponent, PageResetPasswordComponent, PageChangePasswordComponent,
    UiModule
  ]
})
export class PageModule { }
