import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {PageLoginComponent} from './page/page-login/page-login.component';
import {PageHabilitationListComponent} from './page/page-habilitation-list/page-habilitation-list.component';
import {PageActivateAccountComponent} from './page/page-activate-account/page-activate-account.component';
import {PageResetPasswordComponent} from './page/page-reset-password/page-reset-password.component';
import {PageChangePasswordComponent} from './page/page-change-password/page-change-password.component';
import {AuthGuardService} from './service/auth-guard.service';


const routes: Routes = [
  {
    path: 'login', component: PageLoginComponent
  },
  {
    path: 'activate-account', component: PageActivateAccountComponent
  },
  {
    path: 'reset-password', component: PageResetPasswordComponent
  },
  {
    path: 'change-password', component: PageChangePasswordComponent
  },
  {
    path: 'habilitation-list', component: PageHabilitationListComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
