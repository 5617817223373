import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlFormateur, formateurListSchema, formateurSchema } from '../model/xtl-formateur';
import { environment } from 'src/environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import { normalize } from 'normalizr';
import { NetworkService } from './network.service';
import { Observable } from 'rxjs';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class FormateurService implements XtlReducer<XtlFormateur>{

  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }

  create(data: Partial<XtlFormateur>): Observable<any | { error: string; }> {
    data.createdAt = new Date();

    return this.networkService
      .post(environment.apiUrl+'/formateurs',data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Formateur créé',
            detail: 'Le formateur a été correctement créé.',
            life: 5000
          });
        }),
        map(result => {
          if (result.length != 0) {
            return normalize(result,formateurSchema)
          } else {
            return { entities: { formateurs: {} }, result: Array }
          }
        }),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création formateur',
            detail: 'La création du formateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  update(data: Partial<XtlFormateur>): Observable<any | { error: string; }> {
    data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl+'/formateurs/'+data.id,data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Formateur modifié',
            detail: 'Le formateur a été correctement modifié.',
            life: 5000
          });
        }),
        map(result => normalize(result,formateurSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification formateur',
            detail: 'La modification du formateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  remove(target: XtlFormateur): Observable<true | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl+'/formateurs')
    .pipe(
      map(result => normalize(result, formateurListSchema)),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des formateurs',
          detail: 'Le chargement des formateurs a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 5000
        });
        return [];
      })
    );
  }
}
