import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlConfig } from '../model/xtl-config';
import { Observable } from 'rxjs';
import { NetworkService } from './network.service';
import { environment } from 'src/environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements XtlReducer<XtlConfig>{

  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }

  create(data: Partial<XtlConfig>): Observable<any | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  update(data: Partial<XtlConfig>): Observable<any | { error: string; }> {
    // data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl+'/configurations/'+data.id,data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Configuration modifiée',
            detail: 'la configuration a été correctement modifiée.',
            life: 5000
          });
        }),
        map(result => result),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification configuration',
            detail: 'La modification de la configuration a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  remove(target: XtlConfig): Observable<true | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl+'/configurations')
    .pipe(
      map(result => result),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération de la configuration',
          detail: 'Le chargement de la configuration à échoué, si le problème persiste merci de contacter un administrateur.',
          life: 5000
        });
        return [];
      })
    );
  }
}
