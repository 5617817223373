import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlClient, clientSchema, clientListSchema } from '../model/xtl-client';
import { Observable, of, from, BehaviorSubject, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkService } from './network.service';
import { normalize } from 'normalizr';
import {catchError, map, tap} from 'rxjs/operators';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ClientService implements XtlReducer<XtlClient>{

  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }

  create(data: Partial<XtlClient>): Observable<any | { error: string; }> {
    data.createdAt = new Date();

    return this.networkService
      .post(environment.apiUrl+'/clients',data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Compte client créé',
            detail: 'Le compte client a été correctement créé.',
            life: 5000
          });
        }),
        map(result => normalize(result,clientSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création compte client',
            detail: 'La création du compte client a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  update(data: Partial<XtlClient>): Observable<any | { error: string; }> {
    data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl+'/clients/'+data.id,data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Compte client modifié',
            detail: 'le compte client a été correctement modifié.',
            life: 5000
          });
        }),
        map(result => normalize(result,clientSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification compte client',
            detail: 'La modification du compte client a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  groupUpdate(data: Partial<XtlClient>[]): Observable<any> {
    
    //les Observables renvoyés par put
    let updatedObs = [];

    data.forEach(client => {
      client.updatedAt = new Date();   
      updatedObs.push(
        this.networkService
        .put(environment.apiUrl+'/clients/'+client.id,client)
        .pipe(
          map(result => Object.assign({isError: false}, normalize(result,clientSchema))),
          catchError(err => of({isError: true, error: err, name: client.name})),
        )
      )
    })  
    // traitement des messages: forkJoin attend le retour des Obs qu'on lui passe, tout passe par result, y compris les erreur car elles sont catchés par les Obs fournis
    forkJoin<{isError:boolean, error?: string, entities?:{}, result?:number, name?: string}>(updatedObs).subscribe(
      results => { 
        let errorCount = 0;
        let successCount = 0;
        results.forEach(
          result => {
            // un message par erreur
            if (result.isError){
              errorCount++;
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur de modification compte client',
                detail: 'La modification du compte client <b>'+ result.name +'</b> a échouée, si le problème persiste merci de contacter un administrateur.',
                life: 20000
              });
            } else {
              successCount++;
            }
          }  
        )
        // si des erreur et des succes, un message de succes
        if (successCount > 0 && errorCount >0) {
          this.messageService.add({
            severity: 'success',
            summary: 'Comptes clients modifiés',
            detail: 'les autres comptes clients ont été correctement réinitilisés.',
            life: 20000
          });
          // si pas d'erreur et des succes, un message de succes
        } else if (successCount > 0 && errorCount == 0) {
          this.messageService.add({
            severity: 'success',
            summary: 'Comptes clients modifiés',
            detail: 'les comptes clients ont été correctement réinitilisés.',
            life: 5000
          });
        }
        
      }
      
    );
    // forkJoin attend le retour des Obs qu'on lui passe, puis emet un tableau des valeur retournées par les Obs
    return forkJoin(updatedObs);
 
  }

  remove(target: XtlClient): Observable<true | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl+'/clients')
    .pipe(
      map(result => {
        if (result.length != 0) {
          return normalize(result,clientListSchema)
        } else {
          return { entities: { clients: {} }, result: Array }
        }
      }),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des comptes clients',
          detail: 'Le chargement des comptes clients a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 5000
        });
        return [];
      })
    );
  }

}
