import {APP_INITIALIZER, NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE} from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { JwtInterceptor } from './service/interceptor/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageModule } from './page/page.module';
import { ErrorInterceptor } from './service/interceptor/error.interceptor';
import { LoadingInterceptorService } from './service/loading-interceptor.service';
import { AppInitService } from './service/app-init.service';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { LoaderComponent } from './ui/loader/loader.component';
import { DialogModule } from 'primeng/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// Translation gesture
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
registerLocaleData(localeFr, 'fr');
export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.Init();
    }
}
@NgModule({
    declarations: [AppComponent, LoaderComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        PageModule,
        MessagesModule,
        ToastModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DynamicDialogModule,
        DialogModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        DatePipe,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        // angular 9 demande de definir la valeur de la currency par defaut, elle ne récupère pas la locale cf https://angular.io/api/common/CurrencyPipe#description
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptorService, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
