import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlEmail, emailSchema, emailListSchema } from '../model/xtl-email';
import { Observable } from 'rxjs';
import { NetworkService } from './network.service';
import { environment } from 'src/environments/environment';
import { normalize } from 'normalizr';
import {catchError, map, tap} from 'rxjs/operators';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class EmailService implements XtlReducer<XtlEmail>{

  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }

  create(data: Partial<XtlEmail>): Observable<any | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  update(data: Partial<XtlEmail>): Observable<any | { error: string; }> {
    data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl+'/mail_templates/'+data.id,data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Modèle d\'email modifié',
            detail: 'Le modèle d\'email a été correctement modifié.',
            life: 5000
          });
        }),
        map(result => {
          if (result.length != 0) {
            return normalize(result,emailSchema)
          } else {
            return { entities: { emails: {} }, result: Array }
          }
        }),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification email',
            detail: 'La modification du modèle d\'email a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  remove(target: XtlEmail): Observable<true | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl+'/mail_templates')
    .pipe(
      map(result => normalize(result, emailListSchema)),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des modèles emails',
          detail: 'Le chargement des modèles d\'emails a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 5000
        });
        return [];
      })
    );
  }
}
