export enum ActionTypes {
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  APP_INIT = 'APP_INIT',
  SELECT_ITEM = 'SELECT_ITEM',
  ADD_HABILITATION = 'ADD_HABILITATION',
  UPDATE_HABILITATION = 'UPDATE_HABILITATION',
  FETCH_HABILITATION = 'FETCH_HABILITATION',
  UPDATE_FORMATION = 'UPDATE_FORMATION',
  FETCH_FORMATION = 'FETCH_FORMATION',
  ADD_FORMATION = 'ADD_FORMATION',
  FETCH_USER = 'FETCH_USER',
  ADD_USER = 'ADD_USER',
  UPDATE_USER = 'UPDATE_USER',
  FETCH_FORMATEUR = 'FETCH_FORMATEUR',
  ADD_FORMATEUR = 'ADD_FORMATEUR',
  UPDATE_FORMATEUR = 'UPDATE_FORMATEUR',
  FETCH_CLIENT = 'FETCH_CLIENT',
  ADD_CLIENT = 'ADD_CLIENT',
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  FETCH_EMAIL = 'FETCH_EMAIL',
  ADD_EMAIL = 'ADD_EMAIL',
  UPDATE_EMAIL = 'UPDATE_EMAIL',
  UPDATE_CONFIG = 'UPDATE_CONFIG',
  FETCH_CONFIG = 'FETCH_CONFIG',
  GROUPED_UPDATE_CLIENT = "GROUPED_UPDATE_CLIENT"
}
