import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {ButtonBaseComponent} from './button-base/button-base.component';
import {ButtonValidComponent} from './button-valid/button-valid.component';
import {ButtonCancelComponent} from './button-cancel/button-cancel.component';
import {ModalBaseComponent} from './modal-base/modal-base.component';
import {MenuComponent} from './menu/menu.component';
import { SharedModule } from '../shared/shared.module';
import { FieldErrorComponent} from './field-error/field-error.component';
import {ToastComponent} from './toast/toast.component';
import {ToastModule} from 'primeng/toast';

import {IonicModule} from '@ionic/angular';
import {ButtonModule} from 'primeng/button';
import {SidebarModule} from 'primeng/sidebar';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';





@NgModule({
  declarations: [
      HeaderComponent, FooterComponent, MenuComponent,
      ButtonBaseComponent,
      ButtonValidComponent, ButtonCancelComponent,
      ModalBaseComponent,
      FieldErrorComponent,
      ModalBaseComponent,
      ToastComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        ButtonModule,
        SidebarModule,
        RouterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        ToastModule
    ],
    exports: [
        HeaderComponent, FooterComponent, MenuComponent,
        ButtonValidComponent, ButtonCancelComponent,
        FieldErrorComponent, ModalBaseComponent, ToastComponent
    ]
})
export class UiModule { }
