import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlUser, userListSchema, userSchema } from '../model/xtl-user';
import { environment } from 'src/environments/environment';
import {map, catchError, tap} from 'rxjs/operators';

import { NetworkService } from './network.service';
import { normalize } from 'normalizr';
import {Observable} from 'rxjs';
import {MessageService} from "primeng/api";


@Injectable({
  providedIn: 'root'
})
export class UserService implements XtlReducer<XtlUser>{
  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }
  create(data: Partial<XtlUser>): Observable<any | { error: string; }> {
    data.createdAt = new Date();

    return this.networkService
      .post(environment.apiUrl+'/users',data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Compte utilisateur créé',
            detail: 'Le compte utilisateur a été correctement créé.',
            life: 5000
          });
        }),
        map(result => {
          if (result.length != 0) {
            return normalize(result,userSchema)
          } else {
            return { entities: { users: {} }, result: Array }
          }
        }),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création compte utilisateur',
            detail: 'La création du compte utilisateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  update(data: Partial<XtlUser>): Observable<any | { error: string; }> {
    data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl+'/users/'+data.id,data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Compte utilisateur modifié',
            detail: 'le compte utilisateur a été correctement modifié.',
            life: 5000
          });
        }),
        map(result => normalize(result,userSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification compte utilisateur',
            detail: 'La modification du compte utilisateur a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  remove(target: XtlUser): Observable<true | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  fetch(): Observable<any> {
    return this.networkService
    .get(environment.apiUrl+'/users')
    .pipe(
      map(result => normalize(result, userListSchema)),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de récupération des comptes utilisateurs',
          detail: 'Le chargement des comptes utilisateurs a échoué, si le problème persiste merci de contacter un administrateur.',
          life: 5000
        });
        return [];
      })
    );
  }
  get(id: number): Observable<any | { error: string; }> {
    return this.networkService
        .get(environment.apiUrl+'/users/' + id)
        .pipe(
            map(result => normalize(result,userSchema))
        );
  }
}
