import {Component, Input, OnInit} from '@angular/core';
import {Message, MessageService} from 'primeng/api';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {

  @Input() msgs: Message[];

  constructor() { }

  ngOnInit() {}

}
