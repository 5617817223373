import { Component, HostListener, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import {TranslateService} from '@ngx-translate/core';

import {Message, MessageService} from 'primeng/api';

import { StoreService } from './service/store.service';
import { ActionTypes } from './enums/action-types.enum';
import { XtlAction } from './interfaces/xtl-action';
import {DialogService} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [DialogService]
})
export class AppComponent implements OnInit{
  showInstallDialog: boolean = false;
  deferredPrompt: any;
  isIos: boolean = false;
  installText: string;
  isInstalled: boolean = false;
  isDarkTheme: boolean = false;

  constructor(
      private platform: Platform,
      private splashScreen: SplashScreen,
      private statusBar: StatusBar,
      private store: StoreService,
      private translate: TranslateService,
  ) {
    this.initializeApp();
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('fr');
  }

  msgs: Message[] = [];

  ngOnInit(): void {
    if (this.platform.is('ios')) {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.isIos = true;
        this.installText = "Pour installer l\'application sur votre ecran d\'accueil:<br> Cliquez sur <b>Partager</b>  <img width='15px' src='assets/icons/ios-share.png'> <br> Puis <b>Sur l\'écran d\'accueil<b> <img width='15px' src='assets/icons/ios-add.png'>";
        this.showInstallDialog = true;
      }
    } else {
      this.installText = "Souhaitez vous installer l\'application sur votre écran d\'accueil?";
    }
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log('INSTALL PWA');
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    if(!this.isInstalled){
      this.showInstallDialog = true;
    }

  }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showInstallDialog = false;
    this.isInstalled = true;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
        .then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
        });
  }

  onCancel() {
    this.showInstallDialog = false;
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.isDarkTheme = true;
    }

    this.store.state$.subscribe(
        state => {
          console.groupCollapsed('State');
          console.log(state)
          console.groupEnd();
        }
    );

  }
}
