import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { formatDate} from '@angular/common';


import { StoreService } from 'src/app/service/store.service';
import { PdfService } from '../../service/pdf.service';
import { XtlAction } from 'src/app/interfaces/xtl-action';
import { ActionTypes } from 'src/app/enums/action-types.enum';
import { AuthGuardService } from '../../service/auth-guard.service';
import {StatusHabilitation} from '../../enums/status-habilitation.enum';
import { Storage } from '@ionic/storage';
import { take } from 'rxjs/operators';
import { ShowDialogService } from 'src/app/service/show-dialog.service';
import { MessageService } from 'primeng/api';
import {DateCalculatorService} from '../../service/date-calculator.service';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
    selector: 'app-page-habilitation-list',
    templateUrl: './page-habilitation-list.component.html',
    styleUrls: ['./page-habilitation-list.component.scss'],
})
export class PageHabilitationListComponent {

    habilitationTableDatas: any[];
    habilitationsNorm: any;
    cols: any[];
    actionButtons = [];
    newButtonLabel = '';
    recyclingDelay: string;

    habilitationsRef: any[];
    statusRef: any[] = [StatusHabilitation.EN_COURS, StatusHabilitation.ECHEANCE_IMMINENTE, StatusHabilitation.EN_COURS_RENOUVELLEMENT, StatusHabilitation.EN_COURS_RENOUVELLEMENT_DEROGATION, StatusHabilitation.NON_RENOUVELEE];

    userRef: any[];
    clientRef: any[];
    dashDatas: {type: string, number: number, label: string}[];
    filterFromDash: string[];
    filterFromTable: string;

    constructor(
        public store: StoreService,
        private messageService: MessageService,
        private router: Router,
        private pdfService: PdfService,
        public authGuardService: AuthGuardService,
        public showDialogService: ShowDialogService,
        private dateCalculatorService: DateCalculatorService,
        private loaderService: LoaderService,
        private storage: Storage
    ) {

    }

    onPdfClick(_action) {
        this.store.selectHabilitations(_action.id).pipe(take(1)).subscribe(
            hab => {
                if (hab.exemptionPeriod) {
                    hab.deadlineDate = this.dateCalculatorService.dateAddDuration(hab.deadlineDate, hab.exemptionPeriod);
                }
                // get the rh client de la société and add the info to hab
                hab.clientsRH = this.store.getRhClient(hab.holder.client.id);
                this.pdfService.generateHabPdf(hab);
            });
    }

    getHabilitationListData() {
        this.store.selectHabilitations().subscribe(
            habilitations => {
                if (habilitations){
                    this.habilitationTableDatas = [];
                    habilitations.forEach(hab => {
                        // Les habilitations echues ne sont visible que dans l'historique
                        if (hab.status !== StatusHabilitation.ECHUE) {
                            if (hab.exemptionPeriod) {
                                hab.deadlineDate = this.dateCalculatorService.dateAddDuration(hab.deadlineDate, hab.exemptionPeriod);
                            }
                            const habilitation = {
                                id: hab.id,
                                firstname: hab.holder.firstname + ' ' + hab.holder.lastname,
                                client: hab.holder.client.name,
                                formationRef: hab.formation.reference,
                                formationTitle: hab.formation.title,
                                deadlineDate: formatDate(hab.deadlineDate, 'dd/MM/yyyy', 'fr-FR'),
                                formationDate: formatDate(hab.formationDate, 'dd/MM/yyyy', 'fr-FR'),
                                exemptionPeriod: hab.exemptionPeriod,
                                status: hab.status,
                                inRenew: hab.inRenew,
                                formateur: hab.formateur.firstname + ' ' + hab.formateur.lastname,
                                color: hab.formation.color
                            };
                            this.habilitationTableDatas.push(habilitation);
                        }
                    });

                    // this.storage.set('SDCEM-habilitations',this.habilitationTableDatas);
                } else {
                    // this.storage.set('SDCEM-habilitations', null);
                }
            }
        )


    }
    ionViewWillEnter(){
        const fetchHabilitation: XtlAction = {
            type: ActionTypes.FETCH_HABILITATION,
            data: null
        };
        const fetchClient: XtlAction = {
            type: ActionTypes.FETCH_CLIENT,
            data: null
        };
        const fetchUser: XtlAction = {
            type: ActionTypes.FETCH_USER,
            data: null
        };
        this.store.dispatch(fetchHabilitation);
        this.store.dispatch(fetchClient);
        this.store.dispatch(fetchUser);
        this.getHabilitationListData();
    }

    ionViewDidEnter(){
        if(null !== this.store.getCurrentUser()){
            setTimeout(() => {
                if (navigator.onLine) {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Habilitations à jour',
                        detail: 'Vos titres d\'habilitations ont été mis à jour' ,
                        life: 5000
                    });
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Hors connexion',
                        detail: 'Vos titres d\'habilitations n\'ont pas été mis à jour car vous n\'êtes pas connecté à internet' ,
                        life: 10000
                    });
                }
            }, 2000)
        }

    }





}
