import { Injectable } from '@angular/core';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { XtlHabilitation, habilitationSchema, habilitationListSchema } from '../model/xtl-habilitation';
import { Observable } from 'rxjs';
import { normalize } from 'normalizr';
import { NetworkService } from './network.service';
import { environment } from 'src/environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {MessageService} from "primeng/api";




@Injectable({
  providedIn: 'root'
})
export class HabilitationService implements XtlReducer<XtlHabilitation>{

  constructor(
    private networkService: NetworkService,
    private messageService: MessageService
  ) { }

  create(data: Partial<XtlHabilitation>): Observable<any | { error: string; }> {

    data.status = "EN_COURS";
    data.reference = '';
    data.createdAt = new Date();

    return this.networkService
      .post(environment.apiUrl+'/habilitations',data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Habilitation créée',
            detail: 'L\'habilitation a été correctement créée.',
            life: 5000
          });
        }),
        map(result => normalize(result,habilitationSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création habilitation',
            detail: 'La création de l\'habilitation a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }

  update(data: Partial<XtlHabilitation>): Observable<any | { error: string; }> {

    return this.networkService
      .put(environment.apiUrl+'/habilitations/'+data.id,data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Habilitation modifiée',
            detail: 'L\'habilitation a été correctement modifiée.',
            life: 5000
          });
        }),
        map(result => normalize(result,habilitationSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification habilitation',
            detail: 'La modification de l\'habilitation a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }

  remove(target: XtlHabilitation): Observable<true | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  fetch(): Observable<any | { error: string; }> {
    return this.networkService
        .get(environment.apiUrl+'/habilitations')
        .pipe(
            map(result => {
              if (result.length != 0) {
                return normalize(result,habilitationListSchema)
              } else {
                return { entities: { habilitations: {} }, result: Array }
              }
            }),
            catchError((err, caught) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur de récupération des habilitations',
                detail: 'Le chargement des habilitations a échoué, si le problème persiste merci de contacter un administrateur.',
                life: 5000
              });
              return [];
            })
        );
  }
}
