import { Injectable } from '@angular/core';
import { XtlFormation, formationListSchema, formationSchema } from '../model/xtl-formation';
import { XtlReducer } from '../interfaces/xtl-reducer';
import { NetworkService } from './network.service';
import { environment } from 'src/environments/environment';
import {tap, map, catchError} from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Observable } from 'rxjs';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class FormationService implements XtlReducer<XtlFormation>{

  constructor(
      private networkService: NetworkService,
      private messageService: MessageService
  ) { }

  create(data: Partial<XtlFormation>): Observable<any | { error: string; }> {
    data.createdAt = new Date();

    return this.networkService
      .post(environment.apiUrl+'/formations',data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Formation créé',
            detail: 'La formation a été correctement créée.',
            life: 5000
          });
        }),
        map(result => {
          if (result.length != 0) {
            return normalize(result,formationSchema)
          } else {
            return { entities: { formations: {} }, result: Array }
          }
        }),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de création formation',
            detail: 'La création de la formation a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  update(data: Partial<XtlFormation>): Observable<any | { error: string; }> {
    data.updatedAt = new Date();

    return this.networkService
      .put(environment.apiUrl+'/formations/'+data.id,data)
      .pipe(
        tap(result => {
          this.messageService.add({
            severity: 'success',
            summary: 'Formation modifiée',
            detail: 'La formation a été correctement modifiée.',
            life: 5000
          });
        }),
        map(result => normalize(result,formationSchema)),
        catchError((err, caught) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur de modification formation',
            detail: 'La modification de la formation a échouée, si le problème persiste merci de contacter un administrateur.',
            life: 5000
          });
          return [];
        })
      );
  }
  remove(target: XtlFormation): Observable<true | { error: string; }> {
    throw new Error("Method not implemented.");
  }
  fetch(): Observable<any> {
    return this.networkService
        .get(environment.apiUrl+'/formations')
        .pipe(
            map(result => normalize(result,formationListSchema)),
            catchError((err, caught) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur de récupération des formations',
                detail: 'Le chargement des formations a échoué, si le problème persiste merci de contacter un administrateur.',
                life: 5000
              });
              return [];
            })
        );
  }
}
