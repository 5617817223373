import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent {

  @Input() display: boolean = false;
  // info=true : only one OK button, info=false : confirm and cancell buttons
  @Input() info: boolean = false;
  @Input() header: string = '';
  @Input() content: string = '';
  @Output() confirm = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  onConfirmDialog() {
    this.confirm.emit(true);
  }

  onCloseDialog(){
    this.display = false;
    this.cancel.emit(true);
  }


}
