import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import {TranslateService} from '@ngx-translate/core';

import { ServiceModule } from '../service/service.module';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule } from 'primeng/editor';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CarouselModule} from 'primeng/carousel';


@NgModule({

  exports: [
    ServiceModule,
    PasswordModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    SelectButtonModule,
    EditorModule,
    MultiSelectModule,
    InputNumberModule,
    DialogModule,
    TableModule,
    InputSwitchModule,
    CommonModule,
    HttpClientModule,
    ServiceModule,
    TranslateModule,
    // TranslateService,
    AutoCompleteModule,
    ScrollPanelModule,
    CarouselModule
  ]

})
export class SharedModule { }
